import Feature from "../../components/feature/Feature";
import "./whatgpt3.css";

const WhatGPT3 = () => {
  return (
    <div className="gpt3__whatgpt3 section__margin" id="whpt3">
      <div className="gtp3__whatgpt3-feature">
        <Feature
          title="What is GPT-3"
          text="Vi så åsikt vänner mig meddelande som glädje. Hela framsidan av plattan hörde oh borde. Hans defekta eller övertygade bostad egna. Anslutning har gjort omöjliga egna lägenheter högljudda. Vid jointure ladyship en insisterade så mänsklighet han. Vänlig ungkarl entré till on by."
        />
      </div>
      <div className="gpt3__whatgpt3-heading">
        <h1 className="gradient__text">Möjligheterna är bortom din fantasi</h1>
        <p>Utforska biblioteket</p>
      </div>
      <div className="gpt3__whatgpt3-container">
        <Feature
          title="Chatbots"
          text="Vi så åsikt vänner mig meddelande som glädje. Hela framsidan av plattan hörde oh borde."
        />
        <Feature
          title="Kunskapsbas"
          text="Vid jointure ladyship en insisterade så mänsklighet han. Vänlig ungkarl entré till on by. Som omöjligt egna lägenheter b"
        />
        <Feature
          title="Utbildning"
          text="Vid jointure ladyship en insisterade så mänsklighet han. Vänlig ungkarl entré till on by. Som omöjligt egna lägenheter b"
        />
      </div>
    </div>
  );
};

export default WhatGPT3;
