import "./header.css";
import people from "../../assets/people.png";
import ai from "../../assets/ai.png";

const Header = () => {
  return (
    <div className="gpt3__header section__padding " id="home">
      <div className="gpt3__header-content">
        <h1 className="gradient__text">
          Låt oss bygga något fantastiskt med GPT-3 OpenAI
        </h1>
        <p>
          Ändå säng någon för resehjälp överseende obehagligt. Notera tankar all
          träning välsignelse. Överseende sätt allt glädje ändring bullriga
          fästet. Fest vi år att beställa tillåter frågade om
        </p>

        <div className="gpt3__header-content__input">
          <input type="email" placeholder="Din email " />
          <button type="button"> Kom Igång</button>
        </div>

        <div className="gpt3__header-content__people">
          <img src={people} alt="people" />
          <p>
            1 600 personer begärde tillgång till ett besök under de senaste 24
            timmarna
          </p>
        </div>
      </div>
      <div className="gpt3__header-image">
        <img src={ai} alt="ai" />
      </div>
    </div>
  );
};

export default Header;
