import { Feature } from "../../components";
import "./features.css";

const featuresData = [
  {
    title: "Förbättra slutmisstroende omedelbart ",
    text: "Från de fina john han ge av rik han. De åldras och drar fru gillar. Förbättring av slutet misstroende kan omedelbart applåderades hushållet.",
  },
  {
    title: "Bli den tenderade aktiva",
    text: "Anses sympatisera tio ovanligt enstaka hjälp tillräckligt inte. Letter of on bli han tenderade aktiv möjliggöra för att.",
  },
  {
    title: "Meddelande eller är ingenting",
    text: "Led frågar möjlig älskarinna relation elegans äta likaså debattera. Genom meddelande eller är ingenting bland främst adress.",
  },
  {
    title: "Riktigt boy law county",
    text: "Verkligen pojke lag län hon inte kan sin syster. Du är som sex fötter. Bland sex byggs ledighetslagen nu. Inbyggt bord i en snabb rodnad.",
  },
];

const Features = () => {
  return (
    <div className="gpt3__features section__padding" id="features">
      <div className="gpt3__features-heading">
        <h1 className="gradient__text">
          Framtiden är nu och du behöver bara inse det. Gå in i framtiden idag
          och få det att hända.
        </h1>
        <p>Begär tidig åtkomst för att komma igång</p>
      </div>
      <div className="gpt3__features-container">
        {featuresData.map((item, index) => (
          <Feature
            title={item.title}
            text={item.text}
            key={item.title + index}
          />
        ))}
      </div>
    </div>
  );
};

export default Features;
