import "./footer.css";
import gpt3Logo from "../../assets/logo.svg";

const Footer = () => {
  return (
    <div className="gpt3__footer section__padding">
      <div className="gpt3__footer-heading">
        <h1 className="gradient__text">
          Vill du kliva in i framtiden före andra
        </h1>
      </div>

      <div className="gpt3__footer-btn">
        <p>Begär tidig åtkomst</p>
      </div>

      <div className="gpt3__footer-links">
        <div className="gpt3__footer-links_logo">
          <img src={gpt3Logo} alt="logo" />
          <p> Marius Christensen, All Rights Reserved </p>
        </div>
        <div className="gpt3__footer-links_div">
          <h4>Länkar</h4>
          <p>Overons</p>
          <p>Sociala media</p>
          <p>Räknare</p>
          <p>Kontakt</p>
        </div>
        <div className="gpt3__footer-links_div">
          <h4>Företag</h4>
          <p>Allmänna Villkor</p>
          <p>Integritetspolicy</p>
          <p>Kontakt</p>
        </div>
        <div className="gpt3__footer-links_div">
          <h4>Komma i kontakt</h4>
          <p>Värmland</p>
          <p>123-45678</p>
          <p>www.marius-christensen.se</p>
        </div>
      </div>

      <div className="gpt3__footer-copyright">
        <p>&copy; 2024 Marius Christensen. All Rights Reserved </p>
      </div>
    </div>
  );
};

export default Footer;
