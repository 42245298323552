import { useState } from "react";
import "./navbar.css";
import { RiMenu3Line, RiCloseLin, RiCloseLine } from "react-icons/ri";

import "./navbar.css";
import logo from "../../assets/logo.svg";

const Menu = () => (
  <>
    <p>
      <a href="#home">Hem</a>
    </p>
    <p>
      <a href="#whpt3">Vad är GPT3</a>
    </p>
    <p>
      <a href="#possibility">Öppna AI</a>
    </p>
    <p>
      <a href="#features">Fallstudier</a>
    </p>
    <p>
      <a href="#blog">Bibliotek</a>
    </p>
  </>
);

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  return (
    <div className="gpt3__navbar">
      <div className="gpt3__navbar-links">
        <div className="gpt3__navbar-links_logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="gpt3__navbar-links_container">
          <Menu />
        </div>
      </div>
      <div className="gpt3__navbar-sign">
        <p>Logga in</p>
        <button className="gpt3__navbar-signup" type="button">
          Bli Medlem
        </button>
      </div>
      <div className="gpt3__navbar-menu">
        {toggleMenu ? (
          <RiCloseLine
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div className="gpt3__navbar-menu_container scale-up-center">
            <div className="gpt3__navbar-menu_container-links">
              <Menu />
              <div className="gpt3__navbar-menu_container-links-sign">
                <p>Logga in</p>
                <button className="gpt3__navbar-signup" type="button">
                  Bli Medlem
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
